import logo from './logo.svg';
import './App.css';
import  { Routes, Route } from 'react-router-dom';

import Albums from './components/albums';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Albums />} />
    </Routes>
  );
}

export default App;
